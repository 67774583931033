<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Group Type</h2>
            </CCardHeader>
            <CCardBody>
                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                    @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <groupType-grid :key="gridReload" :editClick="editClick" :deleteClick="deleteClick"/>
                <groupType-form ref="groupTypeForm" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import groupTypeGrid from '../GroupType/Grid/GroupTypeGrid.vue';
import groupTypeForm from '../GroupType/Component/GroupTypeForm.vue';
import groupTypeServices from '../GroupType/Script/GroupTypeServices.js';
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

export default {
    name: 'GroupType',
    components: {
        'groupType-grid': groupTypeGrid,
        'groupType-form': groupTypeForm
    },
    mounted () {
        var permission = this.$globalfunc.permissionModule('Group Type');
        document.getElementById("add").disabled = permission.cperms ? false : true;
    },
    data(){
        return{
            gridReload: 0,
        }
    },
    methods: {
        addClick(){
             this.$refs.groupTypeForm.addClick();
        },
        editClick(itemData, view){
            this.$refs.groupTypeForm.editClick(itemData, view);
        },
        deleteClick(GroupTypeId){
            this.$swal(sweet_alert.delete).then((result) => {
                var variables = {
                    id : GroupTypeId
                }

                if (result.isConfirmed == true) {
                    groupTypeServices.deleteQuery(variables).then(res =>{
                        this.reload();
                        this.$swal("Info", response.successDelete, "success");
                    })
                    .catch(e => {
                        var errorMessage = this.$globalfunc.errorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>