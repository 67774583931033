<template>
    <div>
        <div class="modal fade" id="GroupTypeModal" tabindex="-1"
        aria-labelledby="GroupTypeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="GroupTypeModalLabel" class="font-weight-bold">{{ModalTitle}}{{GroupTypeName}}</h4>
                        <a type="button" data-bs-dismiss="modal"
                        aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="GroupTypeName" v-model="GroupTypeName" class="font-weight-bold" />
                                    <label id="errorGroupTypeName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="ActiveFlag" class="font-weight-bold" :options="ActiveFlagData" :value.sync="ActiveFlag" />
                                    <label id="errorActiveFlag" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Item Group</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="ItemGroupId" class="font-weight-bold" :options="ItemGroupData" :value.sync="ItemGroupId" />
                                </CCol>
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GroupTypeServices from '../Script/GroupTypeServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'ItemGroupForm',
    props: ['reload'],
    components: {
    },
    data: function () {
        return {
            ModalTitle : '',
            GroupTypeId: '',
            GroupTypeName: '',
            ActiveFlag: '',
            ActiveFlagData: [],
            ItemGroupId: '',
            ItemGroupData: [],
            Error: 0,
            SaveType: '',
        }
    },
    mounted(){

    },
    methods: {
        addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Group Type: '
            
            this.GroupTypeName = '';

            
            this.ActiveFlagData = [ {value: 'N', label: 'New'} ];
            this.ActiveFlag = this.ActiveFlagData[0].value;
            this.ItemGroupData = [];
            for (let i = 0; i < this.GetItemGroup.length; i++) {
                var str = {
                    value:this.GetItemGroup[i].item_group_id, 
                    label:this.GetItemGroup[i].item_group_name
                }
                this.ItemGroupData.push(str);
            };
            this.ItemGroupId = this.ItemGroupData[0].value;

            this.SaveType = 'Add';
            window.$('#GroupTypeModal').modal('show');
        },
        editClick(GroupTypeData, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Group Type: '
            
            this.GroupTypeId = GroupTypeData.group_type_id;
            this.GroupTypeName = GroupTypeData.group_type_name;

            if(GroupTypeData.active_flag == "N"){
                GroupTypeData.active_flag = "A";
            }
            this.ActiveFlag = GroupTypeData.active_flag;
            this.ActiveFlagData = [ {value: 'A', label: 'Active'},
                                    {value: 'I', label: 'Inactive'} ];

            this.ItemGroupData = [];
            for (let i = 0; i < this.GetItemGroup.length; i++) {
                var str = {
                    value:this.GetItemGroup[i].item_group_id, 
                    label:this.GetItemGroup[i].item_group_name
                }
                this.ItemGroupData.push(str);
            };

            this.ItemGroupId = parseInt(GroupTypeData.item_group_id);
            
            this.SaveType = 'Edit';

            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Group Type: ';
            }

            window.$('#GroupTypeModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.GroupTypeName == ''){
                this.errorShow('errorGroupTypeName');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const GroupTypeData = {
                        group_type_name : this.GroupTypeName,
                        active_flag: this.ActiveFlag,
                        item_group_id: this.ItemGroupId
                    };

                    const variables = {
                        data : GroupTypeData
                    }

                    GroupTypeServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#GroupTypeModal').modal('hide');
                        this.$swal("Info", response.successSave, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const GroupTypeData = {
                        group_type_name : this.GroupTypeName,
                        active_flag : this.ActiveFlag,
                        item_group_id : this.ItemGroupId
                    };

                    const variables = {
                        id : this.GroupTypeId.toString(),
                        data : GroupTypeData
                    }

                    GroupTypeServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#GroupTypeModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    },
    apollo: {
        GetItemGroup:{
            query: GroupTypeServices.getItemGroupQuery()
        }
    }
}
</script>
